// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

const channels = require.context('.', true, /_channel\.js$/)

channels.keys().forEach((ele) => {
  switch(ele) {
    case "./message_pooling_channel.js":
      if(location.pathname.match(/chat\/\d\edit/)) {
        console.log('Connecting WS');
        channels(ele);
      }
    break;
    case "./messages_pooling_channel.js":
       if(location.pathname == "/chat/") {
        console.log('Connecting WS');
        channels(ele);
      }
    break;
    default:
      console.log("No Websocket connection needed", ele);
  }
})
