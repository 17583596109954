import "croppie/croppie";
import "croppie/croppie.css";

let mediaCroppieInstance = null;

$(document).ready(function () {
    // Function to initialize or reinitialize Croppie
    function initializeCroppie(imageTag, width, height, cropType) {
        if (mediaCroppieInstance) {
            mediaCroppieInstance.destroy();
        }

        // Initialize Croppie with the new options
        imageTag.croppie({
            viewport: { width: width, height: height, type: cropType },
            boundary: { width: 500, height: 500 },
            enableResize: true,
        });

        mediaCroppieInstance = imageTag.data('croppie');
    }

    $('[data-toggle="modal"]').on('click', function () {
        const modal = $(this).data('target');
        const imageTag = $(modal).find('.imageToEdit');

        // Initialize Croppie when the modal is first shown
        $(modal).on('shown.bs.modal', function () {
            const width = parseInt($(modal).find('.croppieWidth').val()) || 350;
            const height = parseInt($(modal).find('.croppieHeight').val()) || 350;
            const cropType = $(modal).find('.croppieType').val() || 'square';

            console.log(width, height, cropType);

            initializeCroppie(imageTag, width, height, cropType);
        });

        // Destroy Croppie instance when modal is hidden
        $(modal).on('hidden.bs.modal', function () {
            if (mediaCroppieInstance) {
                mediaCroppieInstance.destroy();
                mediaCroppieInstance = null;
            }
        });

        // Listen for changes in the width, height, or type inputs and reinitialize Croppie
        $(modal).find('.croppieWidth, .croppieHeight, .croppieType').on('change', function () {
            const width = parseInt($(modal).find('.croppieWidth').val()) || 350;
            const height = parseInt($(modal).find('.croppieHeight').val()) || 350;
            const cropType = $(modal).find('.croppieType').val() || 'square';

            console.log(width, height, cropType);

            initializeCroppie(imageTag, width, height, cropType);
        });

        // Save the cropped image when the save button is clicked
        $(modal).find('.saveChanges').on('click', function () {
            mediaCroppieInstance.result({
                type: 'base64',
                format: 'jpeg',
            }).then(function (base64) {
                $.ajax({
                    url: `/admin/media/${modal.split('-')[1]}/edit_image`,
                    type: 'POST',
                    data: { cropped_image: base64 },
                    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
                    success: function (response) {
                        if (response.status === 'success') {
                            window.location.reload();
                        } else {
                            alert('Failed to save changes');
                        }
                    },
                    error: function () {
                        alert('Failed to save changes');
                    }
                });
            });
        });
    });
});
