import "./src-noconflict/ace"
import "./webpack-resolver"

$(document).ready(function () {
    if(document.getElementById('ace-editor')){
        const editor = ace.edit("ace-editor");
        const textarea = $('textarea.ace-editor-input').first().hide();
        editor.setTheme("ace/theme/twilight");
        editor.session.setMode("ace/mode/css");
        editor.getSession().setValue(textarea.val());
        editor.getSession().on('change', function(){
            console.log("changed..");
            textarea.val(editor.getSession().getValue());
        });
    }
})