var remote_options = {};
remote_options['email_template'] = {
  url: '/admin/offercategories/load_options?type=remoteEmailTemplatesHotelId',
  trigger: 'offer_category_hotel_id',
  update: 'offer_category_email_template_id',
  onempty: false
}
remote_options['email_template_booking'] = {
  url: '/admin/offercategories/load_options?type=remoteEmailTemplatesHotelIdBooking',
  trigger: 'offer_category_hotel_id',
  update: 'offer_category_email_template_booking_id',
  onempty: false
}
remote_options['email_template_chat'] = {
  url: '/admin/offercategories/load_options?type=remoteEmailTemplatesHotelIdChat',
  trigger: 'offer_category_hotel_id',
  update: 'offer_category_email_template_chat_id',
  onempty: false
}
remote_options['email_template_chat2staff'] = {
  url: '/admin/offercategories/load_options?type=remoteEmailTemplatesHotelIdChat2Staff',
  trigger: 'offer_category_hotel_id',
  update: 'offer_category_email_template_chat2staff_id',
  onempty: false
}
remote_options['email_template_chatisread'] = {
  url: '/admin/offercategories/load_options?type=remoteEmailTemplatesHotelIdChatisread',
  trigger: 'offer_category_hotel_id',
  update: 'offer_category_email_template_chatisread_id',
  onempty: false
}

window.remote_options = Object.assign(remote_options, window.remote_options);
