export function ckeditor_plugins() {
    let extra_plugins = [
        'colorbutton',
        'colordialog',
        'emoji',
        'find',
        'font',
        'forms',
        'justify',
        'save',
        'showblocks',
        // 'uicolor'
        // 'print',
    ]

    return String(extra_plugins);
}

export function toolbar_groups() {
    return [
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        '/',
        '/',
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'forms', groups: [ 'forms' ] },
        '/',
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'insert', groups: [ 'insert' ] },
        '/',
        { name: 'styles', groups: [ 'styles' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] }
    ]
}