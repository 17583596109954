$(function () {
  if (typeof window.remote_options !== "undefined") {
    for (var i in window.remote_options) {
      var ro = remote_options[i];
      $('#' + ro.trigger).each(function () {
        var $this = $(this);

        var change = (function (_ro) {
          return function (e) {
            var $ip = $('#' + _ro.update);
            if ($this.val()) {
              var val = $ip.val();
              $ip.html('');
              $ip.prop('disabled', true);
              $.getJSON(_ro.url, { value: $this.val(), id: document.location.pathname.split("/")[3] }, function (json) {

                $ip.prop('disabled', false);

                var html = '';
                var valid_vals = [];
                for (var id in json) {
                  html += "<option value='" + id + "'>" + json[id] + "</option>";
                  valid_vals.push(id);
                }
                $ip.html(html);
                $ip.val('');
                $ip.val(json["selected"]);
                if (val) {
                  if (typeof val == 'string' && $.inArray(val, valid_vals) != -1) {
                    $ip.val(val);
                  } else if (typeof val == 'object') {
                    var real_val = [];
                    for (var k = 0; k < val.length; k++) {
                      if ($.inArray(val[k], valid_vals) != -1) {
                        real_val.push(val[k]);
                      }
                    }
                    if (real_val.length) {
                      $ip.val(real_val);
                    }
                  }
                }
              });
            } else {
              if (_ro.onempty) {
                $ip.html('');
                $ip.val('');
              }
            }
          };
        })(ro);
        change(false);
        $this.on('change', change);
      });
    }
  }
});
