import consumer from "./consumer"

consumer.subscriptions.create("MessagesPoolingChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('Websocket connected');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    this.prependMessage(data);
  },

  prependMessage(data) {
    const element = document.querySelector("#chat-table tbody");
    if (element.children[0].dataset["id"] < data.id)  {
      const html = `
        <tr data-id="${data.id}">
          <td>${data.fullname}</td>
          <td style="max-width: 300px;">${data.message}</td>
          <td>${data.created_at}</td>
          <td style="width: 250px;" class="base_actions">
            <ul class="index-actions">
              <li><a href="/chat/${data.guest_id}/edit">Chat ansehen</a></li>
              <li><a href="/chat/${data.guest_id}/offer">View offer</a></li>
              <li><a href="/chat/${data.guest_id}/history">View history</a></li>
            </ul>
          </td>
        </tr>
      `
      element.insertAdjacentHTML("afterbegin", html);
    }
  }
});
