import consumer from "./consumer"

consumer.subscriptions.create({channel: "MessagePoolingChannel", guest_id: 38988}, {
  connected() {
    console.log('WebSocket Connected');
  },

  disconnected() {
    console.log('WebSocket Disconnected');
  },

  received(data) {
    this.prependMessage(data);
  },

  prependMessage(data) {
    const element = document.getElementById("updated-messages");
    const bg = element.dataset["bg"];
    const html = `
      <div class="ml-1 mr-1 p-3 row text-body d-flex justify-content-between align-items-center ${bg}">
        <span class="col-8">${data.message}</span>
        <span class="col"><strong>${ data.fullname }</strong><br>${data.created_at}</span>
      </div>
    `
    element.insertAdjacentHTML("afterbegin", html);

    if(bg == "bg-secondary") {
      element.dataset["bg"] = "bg-white";
    } else if(bg == "bg-white") {
      element.dataset["bg"] = "bg-secondary";
    }
  }
});
