$(document).ready(function() {
    $('.copy-felix-data').on('click', function() {
        // Retrieve the Felix data from the button's data attribute
        const data = $(this).data('felix-data');

        // Convert the data to a JSON-formatted string with indentation for readability
        const jsonData = JSON.stringify(data, null, 2);

        // Use the Clipboard API to copy the JSON string
        navigator.clipboard.writeText(jsonData)
            .then(() => {
                // Optional: Show a message to confirm the copy
                alert('Felix data copied to clipboard as JSON!');
            })
            .catch(err => {
                console.error('Failed to copy Felix data: ', err);
            });
    });
});
