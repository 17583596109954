$(document).ready(function () {
    $(function() {
        $('span.show-image-info').hover(function(e) {
            $(this).children(".image-info-pop-up").show();
        }, function() {
            $(this).children(".image-info-pop-up").hide();
        });
    });

    $('.a-check').click(function (e) {
        e.preventDefault();
        $(this).closest("span").children(".check-boxes").find("input:checkbox").prop('checked', true);
    })

    $('.a-uncheck').click(function (e) {
        e.preventDefault();
        $(this).closest("span").children(".check-boxes").find("input:checkbox").prop('checked', false);
    })

})