$(document).ready(function (){

    //selecting all required elements
    const dropArea = document.querySelector(".drag-area");
    if (dropArea === null) { // return for all other views, where we dont have drag-area
        return;
    }
    const images = dropArea.querySelector("div#images"),
        dragText = dropArea.querySelector("h4"),
        browse_button = dropArea.querySelector("a#browse"),
        clear_button = dropArea.querySelector("a#clear"),
        input = dropArea.querySelector("input");
    // let file; //this is a global variable and we'll use it inside multiple functions

    const upload_url = input.getAttribute("data-upload-url");

    if (browse_button) {
        browse_button.onclick = (event)=>{
            // console.log("browse clicked");
            event.preventDefault();
            input.click(); //if user click on the button then the input also clicked
        }
    }

    if (clear_button) {
        clear_button.onclick = (event)=>{ //to reset
            // console.log("clear clicked");
            event.preventDefault();
            images.innerHTML = '';
            input.value = null;
            dropArea.classList.remove("loaded");
        }
    }

    input.addEventListener("change", function(){
        if(upload_url) {
            console.log("uploading files");
            let formData = new FormData();
            for(let i=0; i < this.files.length; i++) {
                formData.append('images[]', this.files[i]);
            }
            formData.append('json_response', true);
            $.ajax({
                url: upload_url,
                type: 'POST',
                data: formData,
                contentType: false,
                processData: false,
                success: function(data) {
                    // console.log(data);
                    // console.log(data.images);
                    console.log("upload successful");
                    // if (data.urls) {
                    //     for (let i=0; i < data.urls.length; i++) {
                    //         let imgTag = `<img src="${data.urls[i]}" alt="image" />`; //creating an img tag and passing user selected file source inside src attribute
                    //         images.innerHTML += imgTag; //adding that created img tag inside dropArea container
                    //     }
                    // }

                    if (data.errors.length > 0) {
                        const successfully_uploaded = data.urls.length;
                        const error_count = data.errors.length;

                        let message = `Successfully uploaded ${successfully_uploaded} file${successfully_uploaded !== 1 ? 's' : ''}.`;

                        if (error_count > 0) {
                            message += ` However, there were ${error_count} error${error_count !== 1 ? 's' : ''}:`;

                            // Append each error message to the alert
                            data.errors.forEach((error, index) => {
                                message += `\n${index + 1}. ${error}`;
                            });
                        }
                        alert(message);

                    } else {
                        window.location.reload();
                    }
                },
                error: function(err) {
                    console.log(err);
                    alert("Error in uploading files. Please try again.");
                }
            });
        } else {
            dropArea.classList.add("loaded");
            // lopping over all selected files and adding image tag
            for(let i=0; i < this.files.length; i++) {
                const file = this.files[i];
                showFile(file);
            }
        }
    });


    //If user Drag File Over DropArea
    dropArea.addEventListener("dragover", (event)=>{
        // console.log("dragover");
        event.preventDefault(); //preventing from default behaviour
        dropArea.classList.add("active");
        dragText.textContent = "Freigabe zum Hochladen von Dateien";
    });

    //If user leave dragged File from DropArea
    dropArea.addEventListener("dragleave", ()=>{
        // console.log("dragleave");
        dropArea.classList.remove("active");
        dragText.textContent = "Drag and Drop to Upload Files";
    });

    //If user drop File on DropArea
    dropArea.addEventListener("drop", (event)=>{
        event.preventDefault(); //preventing from default behaviour
        // console.log("drop");
        dropArea.classList.add("loaded");
        input.files = event.dataTransfer.files;
        input.dispatchEvent(new Event('change')); // firing input change event manually
    });

    function showFile(file){
        let fileType = file.type; //getting selected file type
        let validExtensions = ["image/jpeg", "image/jpg", "image/png"]; //adding some valid image extensions in array
        if(validExtensions.includes(fileType)){ //if user selected file is an image file
            let fileReader = new FileReader(); //creating new FileReader object
            fileReader.onload = ()=> {
                let fileURL = fileReader.result; //passing user file source in fileURL variable
                // UNCOMMENT THIS BELOW LINE. I GOT AN ERROR WHILE UPLOADING THIS POST SO I COMMENTED IT
                let imgTag = `<img src="${fileURL}" alt="image" />`; //creating an img tag and passing user selected file source inside src attribute
                images.innerHTML += imgTag; //adding that created img tag inside dropArea container
            }
            fileReader.readAsDataURL(file);
        }else{
            alert("This is not an Image File!");
            dropArea.classList.remove("active");
            dragText.textContent = "Drag &amp; Drop to Upload File";
        }
    }

})