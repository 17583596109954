import "croppie/croppie"
import "croppie/croppie.css"

let staffImageDivTag;
let staffImageTag;
let selectedTag;
let croppieInstance;

$(document).ready(function () {
    staffImageDivTag = $('#staff-image-div');
    staffImageTag = $('#staff-image');

    $('#edit-staff-image').on('click', function (event) {
        event.preventDefault();
        initCroppie();
    });

    // Html input tag for image select
    $('#staff_file_img').on('change', function () { readFile(this); });
});

function initCroppie() {
    $('#edit-staff-image').addClass('d-none');

    if (croppieInstance) {
        croppieInstance.destroy();
    }

    croppieInstance = staffImageTag.croppie(
        {
            viewport: { width: 350, height: 350, type: 'circle'},
            boundary: { width: 400, height: 400 },
        }
    );

    staffImageDivTag.on('update.croppie', function (ev, data) {
        // console.log('jquery update', ev, data);

        staffImageTag.croppie('result', {
            type: 'base64',
            format: 'jpeg',
        }).then(function (base64) {
            // add the generated base64 file to a hidden input
            $('#staff_cropped_image').val(base64);
            //optionally we could empty the input file so as
            //not to send both original and cropped.
            // $('#staff_file_img').val('');
        });
    });
}

function readFile(input) {
    if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = function (e) {
            $('#staff-image').attr('src', e.target.result);
            $('#edit-staff-image').removeClass('d-none');
            initCroppie();
            // selectedTag.croppie('bind', {
            //     url: e.target.result
            // }).then(function(){
            //     console.log('jQuery bind complete');
            // });
        }
        reader.readAsDataURL(input.files[0]);
    }
    else {
        swal("Sorry - you're browser doesn't support the FileReader API");
    }
}