 var remote_options = {};
 remote_options['article_package'] = {
     url: '/admin/gob_rules/remoteGobArrangementsByHotel',
     trigger: 'hotelhotel_article_package_rule_lookup_company_id',
     update: 'hotelhotel_article_package_rule_gob_arrangements',
     onempty: true
 }

 remote_options['company_rooms'] = {
     url: '/admin/gob_rules/remoteGobRoomsByHotel2',
     trigger: 'hotelhotel_article_package_rule_lookup_company_id',
     update: 'hotelhotel_article_package_rule_hotelhotel_booking_room_ids',
     onempty: true
 }

 window.remote_options = Object.assign(remote_options, window.remote_options);


